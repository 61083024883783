import { Campaign, Segment, Attribute, Address, Phone } from './model';
export class Filter {
  limit?: number;
  offset?: number;
  start?: number;
  dir?: string;
  column?: number;
  statuses?: string[];
  fromDate?: string;
  toDate?: string;
  createdOnStartDate?: string;
  createdOnEndDate?: string;
  search?: string;
  stageLoadId?: number;
  campaigns?: Campaign[];
  name: string;
  value?: any;
  label?: string;
  placeholder?: string;
  inputType?: string;
  singleSelection?: boolean;
  choices?: Choice[];
  nameProp?: string;
  valueProp?: any;
  selectedCount?: number;
  type?: string;
  singleLabel?: string;
  level?: number;
  section?: Section;
  customer?: any;
  sponsorId?: number;
  packageName?: string;
  promotionId?: number;
  types?: any[];
  memberId?: number;
  jobSelector?: string;
  commId?: number;
  effectiveDate?: string;
  excludeDeleted?: boolean;
  accounts?: any[];
  allowEmpty?: boolean;
  member?: number;
  pSponsorId?: number;
  status?: string;
  partyId?: number;
  activityId?: number;
  if?: boolean;
  includeDeleted?: boolean;
  selectedPackageId?: number;
  includeCount?: boolean;
  sponsorshipTag?: string;
  promotions?: any[];
  multiple?: boolean;
  packageId?: number;
  segmentIds?: number[];
  includeMessages?: boolean;
  clearCallback?: () => void;
  onChangeCallback?: (value: any) => void;
}

export class Choice {
  state?: boolean;
  value: any;
  isDefault?: boolean;
  label?: string;
}

export class SearchFilters {
  filters: Filter[];
  sections?: Section[];
  title?: string;
  inputClass?: string;
  waitUntilApply?: boolean;
  showSearchFilters?: boolean;
  formName: string;
  segmentLessFilter?: Filter;
  buttonsWithText?: boolean;
  addEntityButtons?: Button[];
  newUser?: () => void;
  applyFilters?: () => void;
  searchCallback?: () => void;
  clearAll?: () => void;
  onClear?: () => void;
  clearSelf?: (filter: Filter, individualValue: any) => void;
  getFilters?: (existing: Filter) => any;
  getFilterRef?: (filterName: string) => Filter;
  triggerProcessing?: () => void;
  addActivity?: () => void;
  triggerDebugMode?: () => void;
}

export class Section {
  key: string;
  name?: string;
  open?: boolean;
  additionalRow?: boolean;
}

export class Gender {
  name: string;
}

export class Email {
  emailAddress: string;
  isPrimary: boolean;
}

export class Tab {
  title: string;
  active: boolean;
  idx?: number;
}

export class Button {
  class?: string;
  icon?: any;
  title: string;
  buttonText: string;
  callback: () => void;
  if: boolean;
  disabled?: boolean;
}

export class EndpointType {
  name: string;
}

export class CommunicationType {
  id: number;
  name: string;
  description?: string;
}

export class CommunicationAttribute {
  name: string;
  value: string;
  effectiveDate?: Date;
  expirationDate?: Date;
  displayName?: string;
}

export class CommunicationHistory {
  id: number;
  content: string;
  communicationTimestamp?: string;
  deliveryProtocol?: any;
  delivered?: boolean;
  processingStatus?: any;
}

export class CommunicationContent {
  communicationId: number;
  id: number;
  languageId: number;
  subject: string;
  template: string;
}

export class CommunicationSegment {
  segment: Segment;
  fromDate: Date;
  toDate: Date;
  name?: string;
}

export class ShowDeleted {
  state: boolean;
}

export class DateObject {
  year: number;
  month: number;
  day: number;
}

export class ProcessingLogSearch {
  createdOn: string;
  message: string;
  rejectCode: string;
  promotionSponsorId: string;
  campaignId: string;
  programId: string;
  accountTxnId: string;
  amount: string;
}

export class Manifest {
  fieldMappings: any;
  memberInstrument: MemberInstrument[];
  customAttribute: CustomAttribute[];
  segmentMapping: SegmentMapping[];
  memberRelationship: MemberRelationship[];
}

export class FieldProperty {
  fieldIndex: number;
  fieldName: string;
  fieldDefault: string;
  hashField: string;
  concatField: string;
  concatDelimiter: string;
  required: boolean;
  fieldLength: number;
  fieldRegex: string;
}

export class MemberInstrument {
  instrumentTypeField: string;
  instrumentTypeDefault: string;
  fieldIndex: number;
  primary: boolean;
  expiryField: string;
  expiryDefault: string;
  activationField: string;
  activationDefault: string;
  hashField: string;
  fieldLength: string;
  fieldRegex: string;
  fieldName: string;
  concatField: string;
  concatDelimiter: string;
  required: boolean;
}

export class CustomAttribute {
  attrName: string;
  attrNameIndex: number;
  fieldIndex: number;
  attrValueDefault: string;
  fieldName: string;
  required: boolean;
  fieldLength: number;
  fieldRegex: string;
  effectiveDateIndex: string;
  expirationDateIndex: string;
}

export class SegmentMapping {
  fieldName: string;
  fieldIndex: number;
  fieldDefault: string;
  required: boolean;
  fieldLength: number;
  fieldRegex: string;
  concatField: string;
  concatDelimiter: string;
  effectiveDateIndex: string;
  replace: boolean;
}

export class MemberRelationship {
  relationshipName: string;
  bidirectional: boolean;
  fromRoleName: string;
  toRoleName: string;
  attrName: string;
  fromDate: string;
  toDate: string;
  fieldName: string;
  fieldIndex: number;
  required: boolean;
  fieldLength: number;
  fieldRegex: string;
}

export class ManifestFieldChevronList {
  fieldPropertyIndexes: any;
  memberInstrumentIndex: number;
  customAttributeIndex: number;
  segmentMappingIndex: number;
  memberRelationshipIndex: number;
}

export class DisplayTemplateFieldChevronList {
  alternativeIndex: number;
  promoCodeIndex: number;
}
