import { ResponseHelperService } from './../services/helpers/response-helper.service';
import { MemberRelationship, Manifest } from 'src/app/data/class';
import {
  ManifestFieldChevronList,
  CustomAttribute,
  SegmentMapping,
  MemberInstrument,
} from './../data/class';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { IntegrationsService } from './../services/integrations.service';
import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { Location } from '@angular/common';
import {
  faChevronUp,
  faChevronDown,
  faTimes,
  faSave,
  faRedo,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: 'app-manifest',
  templateUrl: './manifest.component.html',
  styleUrls: ['./manifest.component.scss'],
})
export class ManifestComponent implements OnInit {
  @ViewChildren(NgSelectComponent)
  ngSelectComponentList: QueryList<NgSelectComponent>;
  dropdownDisplay: any[] = [];
  selectedField: any = [];
  selectedEditField: any = [];
  fieldMappings: any[];
  manifestType = '';
  model: Manifest;
  manifest: any;
  displayNames: any;
  rawJSON = '';
  integrationId: number;

  chevronList: boolean[] = [];
  openFieldChevronList: ManifestFieldChevronList =
    new ManifestFieldChevronList();
  faChevronUp = faChevronUp;
  faChevronDown = faChevronDown;
  faTimes = faTimes;
  faTrash = faTrash;
  faSave = faSave;
  faRedo = faRedo;

  constructor(
    private integrationsService: IntegrationsService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private responseHelperService: ResponseHelperService,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.model = {
      fieldMappings: {},
      memberInstrument: [],
      customAttribute: [],
      segmentMapping: [],
      memberRelationship: [],
    };

    this.integrationId = parseInt(this.route.snapshot.paramMap.get('id'), 10);

    this.openFieldChevronList.fieldPropertyIndexes = {};

    this.integrationsService
      .getIntegration(this.integrationId, { format: 'json' })
      .subscribe((response) => {
        this.setData(response);
        if (response.entity.manifest !== null) {
          this.model = JSON.parse(response.entity.manifest).importFile[0];
        }

        Object.keys(this.model.fieldMappings).forEach((mapping) => {
          this.model.fieldMappings[mapping].forEach((field) => {
            this.removeFromDropdown(mapping, field);
          });
        });
      });
  }

  setData(data: any): void {
    if (data.entity.importType.toLowerCase() === 'eligibility') {
      this.manifestType = 'eligibility';
      this.fieldMappings = this.assignMemberMappings();
    } else if (data.entity.importType.toLowerCase() === 'transaction') {
      this.manifestType = 'transaction';
      this.fieldMappings = this.assignTransactionMappings();
    } else {
      this.manifestType = 'organization';
      this.fieldMappings = this.assignOrganizationMappings();
    }

    this.formatDisplayNames();

    this.fieldMappings.forEach((element) => {
      this.selectedField[element.id] = null;
      this.selectedEditField[element.id] = null;
    });

    this.fieldMappings.forEach((mapping) => {
      this.dropdownDisplay[mapping.id] = mapping.fields;
    });

    this.fieldMappings.forEach((property) => {
      this.model.fieldMappings[property.id] = [];
    });
  }

  isFieldIndexUsed(idx: number): boolean {
    if (!idx) {
      return false;
    }
    for (const property in this.model) {
      if (this.model.hasOwnProperty(property)) {
        for (const inner in this.model[property]) {
          if (typeof this.model[property][inner].forEach === 'function') {
            for (const fieldMapping in this.model[property][inner]) {
              if (true) {
                if (
                  this.model[property][inner][fieldMapping].fieldIndex === idx
                ) {
                  this.toastr.warning('Field index already used');
                  return true;
                }
              }
            }
          } else {
            if (this.model[property][inner].fieldIndex === idx) {
              this.toastr.warning('Field index already used');
              return true;
            }
          }
        }
      }
    }
    return false;
  }

  getParams(fields: any, param: any): any {
    const list = [];
    fields.forEach((element) => {
      list.push(element[param]);
    });
    return list;
  }

  removeFromDropdown(propertyId: string, field: any): void {
    let index;
    this.dropdownDisplay[propertyId].forEach((propField, idx) => {
      if (propField.id === field.entityProp) {
        index = idx;
      }
    });
    this.dropdownDisplay[propertyId].splice(index, 1);
    this.dropdownDisplay[propertyId] = this.dropdownDisplay[propertyId].filter(
      (option: any) => {
        return option.id !== field.entityProp;
      }
    );
    this.ngSelectComponentList.forEach((ngSelectComponent: any) => {
      if (ngSelectComponent.element.id === 'select-' + propertyId) {
        ngSelectComponent.handleClearClick();
      }
    });
  }

  addToDropDown(property: any, field: any): void {
    this.dropdownDisplay[property.id] = [
      ...this.dropdownDisplay[property.id],
      {
        id: field.entityProp,
        name: this.displayNames[property.id][field.entityProp],
      },
    ];
  }

  resizeMe(event: any, fieldName: any): void {
    const ibox = $(event.currentTarget).closest('div.ibox');
    const content = ibox.find('div.ibox-content');
    content.slideToggle(200);
    const dropdownName = fieldName.id ? fieldName.id : fieldName;
    this.chevronList[dropdownName] === undefined ||
    !this.chevronList[dropdownName]
      ? (this.chevronList[dropdownName] = true)
      : (this.chevronList[dropdownName] = false);
    ibox.toggleClass('').toggleClass('border-bottom');
    setTimeout(() => {
      ibox.trigger('resize');
      ibox.find('[id^=map-]').trigger('resize');
    }, 50);
  }

  formatDisplayNames(): void {
    this.displayNames = {};
    Object.keys(this.fieldMappings).forEach((mapping) => {
      const mappingId = this.fieldMappings[mapping].id;
      this.displayNames = { ...this.displayNames, [mappingId]: {} };
      this.fieldMappings[mapping].fields.forEach((field) => {
        this.displayNames[mappingId] = {
          ...this.displayNames[mappingId],
          [field.id]: field.name,
        };
      });
    });
  }

  selectField(field: any, property: any): void {
    this.selectedField[property.id] = field;
  }

  /* Field Mapping methods */

  editFieldMapping(property: any, index: any, field: any): void {
    if (this.openFieldChevronList.fieldPropertyIndexes[property.id] === index) {
      this.openFieldChevronList.fieldPropertyIndexes[property.id] = null;
      this.selectedEditField[property.id] = null;
    } else {
      this.openFieldChevronList.fieldPropertyIndexes[property.id] = index;
      this.selectedEditField[property.id] = {
        entityName: field.entityName,
        entityProp: field.entityProp,
      };
    }
  }

  handleFieldPropertyEvent(data: any, property: any): void {
    switch (data.type) {
      case 'add':
        this.addFieldMapping(property, data.value);
        break;
      case 'save':
        this.saveFieldMapping(property, data.index, data.value);
        break;
      default:
        this.resetFieldMapping(property);
        break;
    }
  }

  addFieldMapping(property: any, fieldProperty: any): void {
    this.isFieldIndexUsed(fieldProperty.fieldIndex);
    const selectedFieldModel = {
      ...fieldProperty,
      ...{
        entityName: fieldProperty.id,
        entityProp: this.selectedField[property.id].id,
      },
    };
    if (!this.model.fieldMappings[property.id]) {
      this.model.fieldMappings[property.id] = [];
    }
    this.model.fieldMappings[property.id].push(selectedFieldModel);
    this.removeFromDropdown(property.id, selectedFieldModel);
    this.toastr.success('Field Mapping added');
  }

  saveFieldMapping(property: any, index: number, fieldProperty: any): void {
    if (
      this.model.fieldMappings[property.id][index].fieldIndex !==
      fieldProperty.fieldIndex
    ) {
      this.isFieldIndexUsed(fieldProperty.fieldIndex);
    }
    this.model.fieldMappings[property.id][index] = {
      ...fieldProperty,
      entityName: this.selectedEditField[property.id].entityName,
      entityProp: this.selectedEditField[property.id].entityProp,
    };
    this.openFieldChevronList.fieldPropertyIndexes[property.id] = null;
    this.selectedEditField[property.id] = null;
    this.toastr.success('Field Mapping saved');
  }

  resetFieldMapping(property): void {
    this.openFieldChevronList.fieldPropertyIndexes[property.id] = null;
    this.selectedEditField[property.id] = null;
  }

  removeFieldMapping(property: any, index: number, fieldProperty: any): void {
    this.addToDropDown(property, fieldProperty);
    this.model.fieldMappings[property.id].splice(index, 1);
    this.toastr.success('Field Mapping removed');
  }

  /* Member Instrument methods */

  handleMemberInstrumentEvent(data: any): void {
    switch (data.type) {
      case 'add':
        this.addMemberInstrument(data.value);
        break;
      case 'save':
        this.saveMemberInstrument(data.index, data.value);
        break;
      default:
        this.removeMemberInstrument(data.index);
        break;
    }
  }

  editMemberInstrument(index: number): void {
    if (this.openFieldChevronList.memberInstrumentIndex === index) {
      this.openFieldChevronList.memberInstrumentIndex = null;
    } else {
      this.openFieldChevronList.memberInstrumentIndex = index;
    }
  }

  addMemberInstrument(memberInstrument: MemberInstrument): void {
    this.isFieldIndexUsed(memberInstrument.fieldIndex);
    if (!this.model.memberInstrument) {
      this.model.memberInstrument = [];
    }
    this.model.memberInstrument.push(memberInstrument);
    this.toastr.success('Instrument added');
  }

  saveMemberInstrument(
    index: number,
    memberInstrument: MemberInstrument
  ): void {
    if (
      this.model.memberInstrument[index].fieldIndex !==
      memberInstrument.fieldIndex
    ) {
      this.isFieldIndexUsed(memberInstrument.fieldIndex);
    }
    this.model.memberInstrument[index] = memberInstrument;
    this.toastr.success('Instrument saved');
  }

  removeMemberInstrument(index: number): void {
    this.model.memberInstrument.splice(index, 1);
    this.toastr.success('Instrument removed');
  }

  /* Custom Attribute methods */

  handleCustomAttributeEvent(data: any): void {
    switch (data.type) {
      case 'add':
        this.addCustomAttribute(data.value);
        break;
      case 'save':
        this.saveCustomAttribute(data.index, data.value);
        break;
      default:
        this.removeCustomAttribute(data.index);
        break;
    }
  }

  editCustomAttribute(index: number): void {
    if (this.openFieldChevronList.customAttributeIndex === index) {
      this.openFieldChevronList.customAttributeIndex = null;
    } else {
      this.openFieldChevronList.customAttributeIndex = index;
    }
  }

  addCustomAttribute(customAttribute: CustomAttribute): void {
    this.isFieldIndexUsed(customAttribute.fieldIndex);
    if (!this.model.customAttribute) {
      this.model.customAttribute = [];
    }
    this.model.customAttribute.push(customAttribute);
    this.toastr.success('Attribute added');
  }

  saveCustomAttribute(index: number, customAttribute: CustomAttribute): void {
    if (
      this.model.customAttribute[index].fieldIndex !==
      customAttribute.fieldIndex
    ) {
      this.isFieldIndexUsed(customAttribute.fieldIndex);
    }
    this.model.customAttribute[index] = customAttribute;
    this.toastr.success('Attribute saved');
  }

  removeCustomAttribute(index: number): void {
    this.model.customAttribute.splice(index, 1);
    this.toastr.success('Attribute removed');
  }

  /* Segment Mapping methods */

  handleSegmentMappingEvent(data: any): void {
    switch (data.type) {
      case 'add':
        this.addSegmentMapping(data.value);
        break;
      case 'save':
        this.saveSegmentMapping(data.index, data.value);
        break;
      default:
        this.removeSegmentMapping(data.index);
        break;
    }
  }

  editSegmentMapping(index: number): void {
    if (this.openFieldChevronList.segmentMappingIndex === index) {
      this.openFieldChevronList.segmentMappingIndex = null;
    } else {
      this.openFieldChevronList.segmentMappingIndex = index;
    }
  }

  addSegmentMapping(segmentMapping: SegmentMapping): void {
    this.isFieldIndexUsed(segmentMapping.fieldIndex);
    if (!this.model.segmentMapping) {
      this.model.segmentMapping = [];
    }
    this.model.segmentMapping.push(segmentMapping);
    this.toastr.success('Segment Mapping added');
  }

  saveSegmentMapping(index: number, segmentMapping: SegmentMapping): void {
    if (
      this.model.segmentMapping[index].fieldIndex !== segmentMapping.fieldIndex
    ) {
      this.isFieldIndexUsed(segmentMapping.fieldIndex);
    }
    this.model.segmentMapping[index] = segmentMapping;
    this.toastr.success('Segment Mapping saved');
  }

  removeSegmentMapping(index: number): void {
    this.model.segmentMapping.splice(index, 1);
    this.toastr.success('Segment Mapping removed');
  }

  /* Member Relationship methods */

  handleMemberRelationshipEvent(data: any): void {
    switch (data.type) {
      case 'add':
        this.addMemberRelationship(data.value);
        break;
      case 'save':
        this.saveMemberRelationship(data.index, data.value);
        break;
      default:
        this.removeMemberRelationship(data.index);
        break;
    }
  }

  editMemberRelationship(index: number): void {
    if (this.openFieldChevronList.memberRelationshipIndex === index) {
      this.openFieldChevronList.memberRelationshipIndex = null;
    } else {
      this.openFieldChevronList.memberRelationshipIndex = index;
    }
  }

  addMemberRelationship(memberRelationship: MemberRelationship): void {
    this.isFieldIndexUsed(memberRelationship.fieldIndex);
    if (!this.model.memberRelationship) {
      this.model.memberRelationship = [];
    }
    this.model.memberRelationship.push(memberRelationship);
    this.toastr.success('Relationship added');
  }

  saveMemberRelationship(
    index: number,
    memberRelationship: MemberRelationship
  ): void {
    if (
      this.model.memberRelationship[index].fieldIndex !==
      memberRelationship.fieldIndex
    ) {
      this.isFieldIndexUsed(memberRelationship.fieldIndex);
    }
    this.model.memberRelationship[index] = memberRelationship;
    this.toastr.success('Relationship saved');
  }

  removeMemberRelationship(index: number): void {
    this.model.memberRelationship.splice(index, 1);
    this.toastr.success('Relationship removed');
  }

  createJSON(): void {
    this.updateJSON();
    const packagedModel = { importFile: [this.model] };
    this.integrationsService
      .updateIntegrationManifest(this.integrationId, packagedModel, {
        format: 'json',
      })
      .subscribe(
        (response) => {
          this.toastr.success('Manifest updated');
        },
        (error) => {
          this.responseHelperService.error(this, error.errorMsg);
        }
      );
  }

  cancel() {
    this.location.back();
  }

  updateJSON(): void {
    this.rawJSON = JSON.stringify(this.model, null, '\t');
  }

  assignMemberMappings(): any {
    return [
      {
        id: 'member',
        name: 'Member Data',
        fields: [
          {
            id: 'firstName',
            name: 'First name',
          },
          {
            id: 'lastName',
            name: 'Last name',
          },
          {
            id: 'middleInitial',
            name: 'Middle Initial',
          },
          {
            id: 'suffix',
            name: 'Suffix',
          },
          {
            id: 'gender',
            name: 'Gender',
          },
          {
            id: 'homePhone',
            name: 'Home Phone',
          },
          {
            id: 'businessPhone',
            name: 'Business Phone',
          },
          {
            id: 'mobilePhone',
            name: 'Mobile Phone',
          },
          {
            id: 'dateOfBirth',
            name: 'Date Of Birth',
          },
          {
            id: 'username',
            name: 'Username',
          },
          {
            id: 'password',
            name: 'Password',
          },
          {
            id: 'memberURL',
            name: 'Member URL',
          },
        ],
      },
      {
        id: 'memberAddress',
        name: 'Member Addresses',
        fields: [
          {
            id: 'addressType',
            name: 'Address Type',
          },
          {
            id: 'primary',
            name: 'Primary',
          },
          {
            id: 'address',
            name: 'Address',
          },
          {
            id: 'address2',
            name: 'Address2',
          },
          {
            id: 'city',
            name: 'City',
          },
          {
            id: 'state',
            name: 'State',
          },
          {
            id: 'zip',
            name: 'Zip',
          },
          {
            id: 'country',
            name: 'Country',
          },
        ],
      },
      {
        id: 'memberEmailAddress',
        name: 'Member Email Addresses',
        fields: [
          {
            id: 'emailAddress',
            name: 'Email Address',
          },
          {
            id: 'primary',
            name: 'Primary',
          },
        ],
      },
    ];
  }

  assignTransactionMappings(): any {
    return [
      {
        id: 'transaction',
        name: 'Activiy Data',
        fields: [
          {
            id: 'txnRefNum',
            name: 'Transaction Reference Number',
          },
          {
            id: 'originalTntRefNum',
            name: 'Original Tnt Ref Num',
          },
          {
            id: 'amount',
            name: 'Amount',
          },
          {
            id: 'locationCode',
            name: 'Location Code',
          },
          {
            id: 'activityCode',
            name: 'Activity Code',
          },
          {
            id: 'status',
            name: 'Status',
          },
          {
            id: 'behaviorHierarchySource',
            name: 'Behavior Hierarchy Source Id',
          },
          {
            id: 'txnDateTime',
            name: 'Transaction Date Time',
          },
          {
            id: 'locationHierarchySource',
            name: 'Location Hierarchy Source Id',
          },
        ],
      },
      {
        id: 'txnInstrument',
        name: 'Transaction Instrument',
        fields: [
          {
            id: 'instrumentType',
            name: 'Instrument Type',
          },
          {
            id: 'instrumentNumber',
            name: 'Instrument Number',
          },
        ],
      },
      {
        id: 'txnProduct',
        name: 'Transaction Products',
        fields: [
          {
            id: 'quantity',
            name: 'Quantity',
          },
          {
            id: 'amount',
            name: 'Amount',
          },
          {
            id: 'product',
            name: 'Product',
          },
        ],
      },
    ];
  }

  assignOrganizationMappings(): any {
    return [
      {
        id: 'organization',
        name: 'Organization Data',
        fields: [
          {
            id: 'organizationName',
            name: 'Organization name',
          },
          {
            id: 'description',
            name: 'Description',
          },
          {
            id: 'notes',
            name: 'Notes',
          },
          {
            id: 'createLogin',
            name: 'Create Login',
          },
          {
            id: 'memberCredential',
            name: 'Member Credential',
          },
          {
            id: 'username',
            name: 'Username',
          },
          {
            id: 'password',
            name: 'Password',
          },
          {
            id: 'yearStart',
            name: 'Year Start',
          },
          {
            id: 'sponsor',
            name: 'Sponsor',
          },
        ],
      },
      {
        id: 'organizationAddress',
        name: 'Organization Address',
        fields: [
          {
            id: 'primary',
            name: 'Primary',
          },
          {
            id: 'address',
            name: 'Address',
          },
          {
            id: 'address2',
            name: 'Address2',
          },
          {
            id: 'city',
            name: 'City',
          },
          {
            id: 'state',
            name: 'State',
          },
          {
            id: 'zip',
            name: 'Zip',
          },
          {
            id: 'country',
            name: 'Country',
          },
        ],
      },
    ];
  }
}
