<div class="wrapper wrapper-content">
  <div class="row">
    <div class="col-lg-6">
      <div class="row" *ngFor="let property of fieldMappings">
        <div class="col-lg-12">
          <div class="ibox collapsed border-bottom">
            <div
              class="ibox-title collapse-link"
              (click)="resizeMe($event, property)"
            >
              <h5>{{ property.name }}</h5>
              <div class="ibox-tools">
                <a>
                  <fa-icon
                    [icon]="
                      chevronList[property.id] ? faChevronUp : faChevronDown
                    "
                  ></fa-icon>
                </a>
              </div>
            </div>
            <form method="get" class="form-horizontal">
              <div class="ibox-content">
                <div ngbAccordion [closeOthers]="true">
                  <div
                    ngbAccordionItem
                    *ngFor="
                      let field of model.fieldMappings[property.id];
                      let i = index
                    "
                    id="static-{{ i }}"
                  >
                    <div ngbAccordionHeader>
                      <div
                        class="d-flex allign-items-center justify-content-between"
                      >
                        <h5 class="m-0">
                          {{ displayNames[property.id][field.entityProp] }}
                          {{ field.fieldName ? "- " + field.fieldName : "" }}
                        </h5>
                        <div>
                          <button
                            ngbAccordionToggle
                            class="btn btn-dark-blue"
                            (click)="editFieldMapping(property, i, field)"
                            title="View/Edit"
                          >
                            <fa-icon
                              [icon]="
                                i ===
                                openFieldChevronList.fieldPropertyIndexes[
                                  property.id
                                ]
                                  ? faChevronUp
                                  : faChevronDown
                              "
                            ></fa-icon>
                          </button>
                          <button
                            class="btn btn-dark-blue"
                            (click)="removeFieldMapping(property, i, field)"
                            title="Remove"
                          >
                            <fa-icon [icon]="faTrash"></fa-icon>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div ngbAccordionCollapse>
                      <h2>
                        {{ field.fieldName }}
                      </h2>
                      <app-manifest-field-property-form
                        [index]="i"
                        [fieldProperty]="model.fieldMappings[property.id][i]"
                        (fieldPropertyEvent)="
                          handleFieldPropertyEvent($event, property);
                          $event.type !== 'add' ? acc.toggle('static-' + i) : ''
                        "
                      ></app-manifest-field-property-form>
                    </div>
                  </div>
                </div>
                <div id="selected-field" class="row">
                  <label class="col-sm-offset-1 col-sm-2 control-label"
                    >Field</label
                  >
                  <div class="col-sm-4">
                    <ng-select
                      id="select-{{ property.id }}"
                      [items]="dropdownDisplay[property.id]"
                      (change)="selectField($event, property)"
                      bindLabel="name"
                      bindValue="id"
                      [clearable]="false"
                    ></ng-select>
                  </div>
                </div>
                <app-manifest-field-property-form
                  *ngIf="selectedField[property.id]"
                  [index]="null"
                  (fieldPropertyEvent)="
                    handleFieldPropertyEvent($event, property)
                  "
                ></app-manifest-field-property-form>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="row" [hidden]="!(manifestType !== 'transaction')">
        <div class="col-lg-12">
          <div class="ibox collapsed border-bottom">
            <div
              class="ibox-title collapse-link"
              (click)="resizeMe($event, 'memberInstrument')"
            >
              <h5>Instruments</h5>
              <div class="ibox-tools">
                <a>
                  <fa-icon
                    [icon]="
                      chevronList['memberInstrument']
                        ? faChevronUp
                        : faChevronDown
                    "
                  ></fa-icon>
                </a>
              </div>
            </div>
            <form method="get" class="form-horizontal">
              <div class="ibox-content">
                <div ngbAccordion [closeOthers]="true">
                  <div
                    ngbAccordionItem
                    *ngFor="let field of model.memberInstrument; let i = index"
                    id="static-{{ i }}"
                  >
                    <div ngbAccordionHeader>
                      <div
                        class="d-flex allign-items-center justify-content-between"
                      >
                        <h5 class="m-0">
                          Instrument
                          {{
                            field.InstrumentTypeField
                              ? "- " + field.InstrumentTypeField
                              : ""
                          }}
                        </h5>
                        <div>
                          <button
                            ngbAccordionToggle
                            class="btn btn-dark-blue"
                            (click)="editMemberInstrument(i)"
                            title="View/Edit"
                          >
                            <fa-icon
                              [icon]="
                                i === openFieldChevronList.memberInstrumentIndex
                                  ? faChevronUp
                                  : faChevronDown
                              "
                            ></fa-icon>
                          </button>
                          <button
                            class="btn btn-dark-blue"
                            (click)="removeMemberInstrument(i)"
                            title="Remove"
                          >
                            <fa-icon [icon]="faTrash"></fa-icon>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div ngbAccordionCollapse>
                      <h2>
                        {{ field.instrumentTypeField }} -
                        {{ field.instrumentTypeDefault }}
                      </h2>
                      <app-manifest-member-instrument-form
                        [index]="i"
                        [memberInstrument]="model.memberInstrument[i]"
                        (memberInstrumentEvent)="
                          handleMemberInstrumentEvent($event)
                        "
                      ></app-manifest-member-instrument-form>
                    </div>
                  </div>
                </div>
                <h2>New Instrument</h2>
                <app-manifest-member-instrument-form
                  [index]="null"
                  (memberInstrumentEvent)="handleMemberInstrumentEvent($event)"
                ></app-manifest-member-instrument-form>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="ibox collapsed border-bottom">
            <div
              class="ibox-title collapse-link"
              (click)="resizeMe($event, 'customAttribute')"
            >
              <h5>Attributes</h5>
              <div class="ibox-tools">
                <a>
                  <fa-icon
                    [icon]="
                      chevronList['customAttribute']
                        ? faChevronUp
                        : faChevronDown
                    "
                  ></fa-icon>
                </a>
              </div>
            </div>
            <form method="get" class="form-horizontal">
              <div class="ibox-content">
                <div ngbAccordion [closeOthers]="true">
                  <div
                    ngbAccordionItem
                    *ngFor="let field of model.customAttribute; let i = index"
                    id="static-{{ i }}"
                  >
                    <div ngbAccordionHeader>
                      <div
                        class="d-flex allign-items-center justify-content-between"
                      >
                        <h5 class="m-0">
                          Attribute
                          {{
                            model.customAttribute[i].attrName
                              ? "- " + model.customAttribute[i].attrName
                              : field.AttrNameIndex
                          }}
                        </h5>
                        <div>
                          <button
                            ngbAccordionToggle
                            class="btn btn-dark-blue"
                            (click)="editCustomAttribute(i)"
                            title="View/Edit"
                          >
                            <fa-icon
                              [icon]="
                                i === openFieldChevronList.customAttributeIndex
                                  ? faChevronUp
                                  : faChevronDown
                              "
                            ></fa-icon>
                          </button>
                          <button
                            class="btn btn-dark-blue"
                            (click)="removeCustomAttribute(i)"
                            title="Remove"
                          >
                            <fa-icon [icon]="faTrash"></fa-icon>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div ngbAccordionCollapse>
                      <h2>{{ field.attrName }} - {{ field.attrNameIndex }}</h2>
                      <app-manifest-custom-attribute-form
                        [index]="i"
                        [customAttribute]="model.customAttribute[i]"
                        (customAttributeEvent)="
                          handleCustomAttributeEvent($event)
                        "
                      ></app-manifest-custom-attribute-form>
                    </div>
                  </div>
                </div>
                <h2>New Attribute</h2>
                <app-manifest-custom-attribute-form
                  [index]="null"
                  (customAttributeEvent)="handleCustomAttributeEvent($event)"
                ></app-manifest-custom-attribute-form>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="row" [hidden]="!(manifestType !== 'transaction')">
        <div class="col-lg-12">
          <div class="ibox collapsed border-bottom">
            <div
              class="ibox-title collapse-link"
              (click)="resizeMe($event, 'segmentMapping')"
            >
              <h5>Segment Mappings</h5>
              <div class="ibox-tools">
                <a>
                  <fa-icon
                    [icon]="
                      chevronList['segmentMapping']
                        ? faChevronUp
                        : faChevronDown
                    "
                  ></fa-icon>
                </a>
              </div>
            </div>
            <form method="get" class="form-horizontal">
              <div class="ibox-content">
                <div ngbAccordion [closeOthers]="true">
                  <div
                    ngbAccordionItem
                    *ngFor="let field of model.segmentMapping; let i = index"
                    id="static-{{ i }}"
                  >
                    <div ngbAccordionHeader>
                      <div
                        class="d-flex allign-items-center justify-content-between"
                      >
                        <h5 class="m-0">
                          Segment Mapping
                          {{ field.fieldName ? "- " + field.fieldName : "" }}
                        </h5>
                        <div>
                          <button
                            ngbAccordionToggle
                            class="btn btn-dark-blue"
                            (click)="editSegmentMapping(i)"
                            title="View/Edit"
                          >
                            <fa-icon
                              [icon]="
                                i === openFieldChevronList.customAttributeIndex
                                  ? faChevronUp
                                  : faChevronDown
                              "
                            ></fa-icon>
                          </button>
                          <button
                            class="btn btn-dark-blue"
                            (click)="removeSegmentMapping(i)"
                            title="Remove"
                          >
                            <fa-icon [icon]="faTrash"></fa-icon>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div ngbAccordionCollapse>
                      <h2>
                        {{ field.fieldName }}
                      </h2>
                      <app-manifest-segment-mapping-form
                        [index]="i"
                        [segmentMapping]="model.segmentMapping[i]"
                        (segmentMappingEvent)="
                          handleSegmentMappingEvent($event)
                        "
                      ></app-manifest-segment-mapping-form>
                    </div>
                  </div>
                </div>
                <h2>New Segment Mapping</h2>
                <app-manifest-segment-mapping-form
                  [index]="null"
                  (segmentMappingEvent)="handleSegmentMappingEvent($event)"
                ></app-manifest-segment-mapping-form>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="row" [hidden]="!(manifestType !== 'transaction')">
        <div class="col-lg-12">
          <div class="ibox collapsed border-bottom">
            <div
              class="ibox-title collapse-link"
              (click)="resizeMe($event, 'memberRelationship')"
            >
              <h5>Relationships</h5>
              <div class="ibox-tools">
                <a>
                  <fa-icon
                    [icon]="
                      chevronList['memberRelationship']
                        ? faChevronUp
                        : faChevronDown
                    "
                  ></fa-icon>
                </a>
              </div>
            </div>
            <form method="get" class="form-horizontal">
              <div class="ibox-content">
                <div ngbAccordion [closeOthers]="true">
                  <div
                    ngbAccordionItem
                    *ngFor="
                      let field of model.memberRelationship;
                      let i = index
                    "
                    id="static-{{ i }}"
                  >
                    <div ngbAccordionHeader>
                      <div
                        class="d-flex allign-items-center justify-content-between"
                      >
                        <h5 class="m-0">
                          Relationship
                          {{
                            field.relationshipName
                              ? "- " + field.relationshipName
                              : ""
                          }}
                        </h5>
                        <div>
                          <button
                            ngbAccordionToggle
                            class="btn btn-dark-blue"
                            (click)="editMemberRelationship(i)"
                            title="View/Edit"
                          >
                            <fa-icon
                              [icon]="
                                i ===
                                openFieldChevronList.memberMemberRelationship
                                  ? faChevronUp
                                  : faChevronDown
                              "
                            ></fa-icon>
                          </button>
                          <button
                            class="btn btn-dark-blue"
                            (click)="removememberRelationship(i)"
                            title="Remove"
                          >
                            <fa-icon [icon]="faTrash"></fa-icon>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div ngbAccordionCollapse>
                      <h2>
                        {{ field.relationshipName }}
                      </h2>
                      <app-manifest-member-relationship-form
                        [index]="i"
                        [memberRelationship]="model.memberRelationship[i]"
                        (memberRelationshipEvent)="
                          handleMemberRelationshipEvent($event)
                        "
                      ></app-manifest-member-relationship-form>
                    </div>
                  </div>
                </div>
                <h2>New Relationship</h2>
                <app-manifest-member-relationship-form
                  [index]="null"
                  (memberRelationshipEvent)="
                    handleMemberRelationshipEvent($event)
                  "
                ></app-manifest-member-relationship-form>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="ibox collapsed border-bottom">
            <form method="get" class="form-horizontal">
              <div class="ibox-content" style="display: block">
                <h3>Cancel/Save Manifest</h3>
                <div class="form-group m-t">
                  <div class="row">
                    <div class="col-md-4 offset-md-1">
                      <button
                        (click)="cancel()"
                        class="btn btn-attention"
                        type="reset"
                        title="Cancel"
                      >
                        <fa-icon [icon]="faTimes"></fa-icon>
                      </button>
                      <button
                        id="create-json"
                        class="btn btn-dark-blue"
                        type="button"
                        (click)="createJSON()"
                        title="Save JSON"
                      >
                        <fa-icon [icon]="faSave"></fa-icon>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="ibox collapsed border-bottom">
        <div
          class="ibox-title collapse-link"
          (click)="resizeMe($event, 'xmlPreview')"
        >
          <h5>JSON preview</h5>
          <div class="ibox-tools">
            <a>
              <fa-icon
                [icon]="chevronList['xmlPreview'] ? faChevronUp : faChevronDown"
              ></fa-icon>
            </a>
          </div>
        </div>
        <div class="ibox-content json-preview">
          <div class="row m-b-sm">
            <button
              id="preview-json"
              class="btn btn-dark-blue"
              type="button"
              (click)="updateJSON()"
              title="Refresh"
            >
              <fa-icon [icon]="faRedo"></fa-icon>
            </button>
            <small>Click "Refresh" to update.</small>
          </div>
          <div class="row">
            <pre ng-bind="rawJSON" class="w-100"
              >{{ rawJSON }}
              </pre
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
